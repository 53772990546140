import { createApp } from 'vue';

import UploadDropArea from 'components/upload/UploadDropArea.vue';
import uploadStore from 'store/upload';
import { i18n } from 'locales';

export default function initializeUploadDropArea() {
  const mountElement = '#vue-upload-drop-area';
  const uploadArea = document.querySelector(mountElement);
  if (uploadArea === null) {
    return;
  }

  const store = uploadStore(null, uploadArea.dataset.newProjectPath);
  const app = createApp(UploadDropArea);
  app.use(store);
  app.use(i18n);

  app.mount(mountElement);
}
