<template>
  <div
    class="upload-box"
    @dragover="preventDefault"
    @drop.prevent="fileDropped"
    @dragleave="preventDefault"
  >
    <h4>{{ $t('dropHere') }}</h4>
    <div class="text-center upload-info" v-html="$t('uploadInfo')" />
    <img src="~images/icon__docs.svg" alt="Icon document">
    <div class="actions">
      <p v-if="inProgress" v-html="message || $t('uploading')" />
      <p v-if="failed" v-html="message || $t('problem')" />

      <progress
        v-if="inProgress"
        class="success"
        max="100"
        :value="progress"
      />

      <form name="upload" class="hidden">
        <input
          ref="filePicker"
          id="file"
          type="file"
          name="upload[file]"
          @change="fileSelected"
        >
      </form>

      <template v-if="!inProgress">
        <a class="button large primary" @click.prevent="selectFile" href="#">
          {{ $t('selectFile') }}
        </a>
        <p>
          <small>
            <i class="fas fa-lock" />&nbsp;{{ $t('secureUpload') }}
          </small>
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import { components } from 'locales';
import { mapState, mapActions } from 'vuex';
import { pageUrl } from 'helper/clientDetails';

export default {
  computed: {
    ...mapState(['inProgress', 'failed', 'message', 'progress']),
  },
  methods: {
    ...mapActions(['upload']),
    selectFile() {
      this.$refs.filePicker.click();
    },
    fileSelected(evt) {
      window.plausible('drop_zone_upload', { u: pageUrl(), props: { method: 'file-picker' } });
      this.upload(Array.from(evt.target.files));
    },
    fileDropped(evt) {
      window.plausible('drop_zone_upload', { u: pageUrl(), props: { method: 'drop' } });
      this.upload(Array.from(evt.dataTransfer.files));
    },
    preventDefault(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    },
  },
  i18n: components.Upload,
};
</script>

<style lang="scss" scoped>
@use 'stylesheets/foundation/colors';

.upload-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  padding: 4rem 2rem 2rem 2rem;

  background: colors.$white;
  border-radius: 12px;

  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.button {
  font-weight: 700;
}

.hidden {
  display: none;
}
</style>
